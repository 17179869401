import { Link } from "gatsby"
import React from "react"

const Header = ({ siteTitle, siteDescription }) => (
  <header className="header">
    <div>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `rgba(0,0,0,0.9)`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div className="info">
        {siteDescription}
        <br />
        Find some challenges to test your skills or get your imagination
        flowing.
      </div>
    </div>
  </header>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
