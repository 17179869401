import React from "react"

import { buildSentence } from "../service/sentence-builder"
import { CHALLENGE } from "../service/template-variables"

function Generator() {
  const [difficulty, setDifficulty] = React.useState(CHALLENGE.ANY)
  const [challenge, setChallenge] = React.useState(buildSentence(difficulty))
  const newChallenge = () => setChallenge(buildSentence(difficulty))

  return (
    <div>
      <div className="difficulty">
        <span className="label">Difficulty:</span>
        <label htmlFor={CHALLENGE.ANY} className="radio-container">
          <input
            type="radio"
            name="difficulty"
            id={CHALLENGE.ANY}
            value={CHALLENGE.ANY}
            onChange={() => setDifficulty(CHALLENGE.ANY)}
            defaultChecked
          />
          Any
          <span className="checkmark" />
        </label>
        <label htmlFor={CHALLENGE.EASY} className="radio-container">
          <input
            type="radio"
            name="difficulty"
            id={CHALLENGE.EASY}
            value={CHALLENGE.EASY}
            onChange={() => setDifficulty(CHALLENGE.EASY)}
          />
          Easy
          <span className="checkmark" />
        </label>
        <label htmlFor={CHALLENGE.MEDIUM} className="radio-container">
          <input
            type="radio"
            name="difficulty"
            id={CHALLENGE.MEDIUM}
            value={CHALLENGE.MEDIUM}
            onChange={() => setDifficulty(CHALLENGE.MEDIUM)}
          />
          Medium
          <span className="checkmark" />
        </label>
        <label htmlFor={CHALLENGE.HARD} className="radio-container">
          <input
            type="radio"
            name="difficulty"
            id={CHALLENGE.HARD}
            value={CHALLENGE.HARD}
            onChange={() => setDifficulty(CHALLENGE.HARD)}
          />
          Hard
          <span className="checkmark" />
        </label>
      </div>
      <button onClick={newChallenge} className="button">
        New Challenge
      </button>
      <br />
      <output
        dangerouslySetInnerHTML={{ __html: challenge }}
        className="challenge"
      />
    </div>
  )
}

Generator.defaultProps = {}

export default Generator
