const TYPES = {
  PROJECT: "PROJECT",
  COMPANY: "COMPANY",
  TECHNOLOGY: "TECHNOLOGY",
  SCENARIO: "SCENARIO",
}

const CHALLENGE = {
  EASY: "EASY",
  MEDIUM: "MEDIUM",
  HARD: "HARD",
  ANY: "ANY",
}

const CHALLENGE_VALUE = {
  EASY: 0,
  MEDIUM: 1,
  HARD: 2,
  ANY: 2,
}

export { TYPES, CHALLENGE, CHALLENGE_VALUE }
