import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="site-container">
      <Header
        siteTitle={data.site.siteMetadata.title}
        siteDescription={data.site.siteMetadata.description}
      />
      <main className="main">{children}</main>
      <footer className="footer">
        Made by
        {` `}
        <a href="https://sethcorker.com/" target="_blank" className="external">
          Seth Corker
        </a>
        <span className="reference">
          Inspired by{" "}
          <a
            href="https://sharpen.design/"
            target="_blank"
            className="external"
            rel="noopener noreferrer"
          >
            sharpen.design
          </a>
        </span>
      </footer>
    </div>
  )
}

export default Layout
