import sentences from "./entities/sentences.json"
import companies from "./entities/companies.json"
import projects from "./entities/projects.json"
import technologies from "./entities/technologies.json"
import constraints from "./entities/constraints.json"
import colours from "./entities/backgroundColours.json"
import scenarios from "./entities/scenarios.json"

import { TYPES, CHALLENGE, CHALLENGE_VALUE } from "./template-variables"

export function countPermutations() {
  return (
    companies.length *
    sentences.length *
    projects[CHALLENGE.EASY].length *
    projects[CHALLENGE.MEDIUM].length *
    projects[CHALLENGE.HARD].length *
    technologies[CHALLENGE.EASY].length *
    technologies[CHALLENGE.MEDIUM].length *
    technologies[CHALLENGE.HARD].length *
    constraints.length
  )
}

export function buildSentence(difficulty) {
  return (
    getDeps(pickRandom(sentences), difficulty) +
    pickConstraints(CHALLENGE_VALUE[difficulty])
  )
}

export function pickRandom(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}

function pickConstraints(difficulty = 3) {
  const numOfConstraints = Math.floor(Math.random() * difficulty)
  const picks = []

  for (let i = 0; i < numOfConstraints; i++) {
    const constraint = pickRandom(constraints)

    if (!picks.includes(constraint)) {
      picks.push(constraint)
    } else {
      i--
    }
  }

  if (picks.length > 0) {
    return " " + picks.join(", ") + "."
  }

  return "."
}

function getDeps(sentence, difficulty) {
  const shouldUpcaseFirst = sentence[0] === "{"

  const parts = sentence
    .match(/{[A-Z]+}/g)
    .map(x => x.replace("{", "").replace("}", ""))
    .map((x, i) => {
      const val = pickFromType(x, difficulty)

      return {
        [x]: `<span class="type-${x.toLowerCase()}" style="text-decoration-color: ${pickRandom(
          colours
        )};">${i === 0 && shouldUpcaseFirst ? upcaseFirst(val) : val}</span>`,
      }
    })

  let newSentence = sentence
  parts.forEach(part => {
    newSentence = newSentence.replace(
      `{${Object.keys(part)[0]}}`,
      Object.values(part)[0]
    )
  })

  return newSentence
}

function pickFromType(type, difficulty) {
  switch (type) {
    case TYPES.COMPANY:
      return pickRandom(companies)

    case TYPES.PROJECT:
      if (difficulty === CHALLENGE.ANY) {
        return pickRandom(anyDifficultyOf(projects))
      }

      return pickRandom(projects[difficulty])

    case TYPES.TECHNOLOGY:
      if (difficulty === CHALLENGE.ANY) {
        return pickRandom(anyDifficultyOf(technologies))
      }

      return pickRandom(projects[difficulty])

    case TYPES.SCENARIO:
      return pickRandom(scenarios)

    default:
      console.error("Invalid type: ", type)
      return "something"
  }
}

function anyDifficultyOf(store) {
  return [
    ...store[CHALLENGE.EASY],
    ...store[CHALLENGE.MEDIUM],
    ...store[CHALLENGE.HARD],
  ]
}

function upcaseFirst(str) {
  return str.charAt(0).toUpperCase() + str.substring(1)
}
